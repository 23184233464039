<template>
  <el-card shadow="hover" header="" class="x-card-title-slot">


    <el-steps align-center :active="active" finish-status="success">
      <el-step title="开发者ID设置">
      </el-step>
      <el-step title="代码配置"></el-step>
    </el-steps>
    <el-form v-if="!auth_status" :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px"
      class="demo-ruleForm">
      <el-form-item label="APPID" prop="app_id" style="margin-bottom: 30px">
        <el-input v-model.trim="ruleForm.app_id"></el-input>
      </el-form-item>
      <el-form-item label="APPSecret" prop="app_secret">
        <el-input v-model.trim="ruleForm.app_secret"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button style="width: 200px;margin-top:20px" type="primary" @click="save">下一步</el-button>
      </el-form-item>
    </el-form>
    <p style="text-align: center;margin:100px" v-else>请联系客服人员进行后续小程序代码方面的信息配置。</p>
  </el-card>
</template>
<script>
import * as API_shop from "@/api/shop";
export default {
  name: "miniProgramSettings",
  data () {
    return {
      active: 0,
      rules: {
        app_id: [{ required: true, message: "请输入APPID", trigger: "blur" },
        {
          required: true, validator: (role, value, callBack) => {
            if (!(/^[a-zA-Z0-9]+$/.test(value))) {
              callBack(new Error('请输入数字、英文字母'))
            } else {
              callBack()
            }
          },
          trigger: 'blur'
        }
        ],
        app_secret: [{ required: true, message: "请输入APPSecret", trigger: "blur" },
        {
          required: true, validator: (role, value, callBack) => {
            if (!(/^[a-zA-Z0-9]+$/.test(value))) {
              callBack(new Error('请输入数字、英文字母'))
            } else {
              callBack()
            }
          },
          trigger: 'blur'
        }],
      },
      auth_status: 0, //认证状态
      ruleForm: {
        app_id: "",
        app_secret: ''
      },
    };
  },
  mounted () {
    //写一个只能输入数字字母的正则表达式


    this.getDetail();
  },
  methods: {
    getDetail () {
      API_shop.getXCXContent().then((res) => {
        this.auth_status = res.auth_status;
        if (this.auth_status === 1) {
          this.active = 1
        }
      });
    },
    save () {//下一步
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          let par = {
            ...this.ruleForm
          };
          API_shop.saveXCXContent(par).then((res) => {
            this.auth_status = res.auth_status;
            if (this.auth_status === 1) {
              this.active = 1
            }
          });
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.demo-ruleForm {
  margin: 80px auto 0;
  width: 500px;
}

::v-deep .el-step.is-horizontal .el-step__line {
  top: 23px;
}

::v-deep .el-step__icon {
  width: 50px;
  height: 50px;
  font-size: 27px;
}
</style>